<template>
    <div class="c_content_container culture">
		<!-- 상단배너 START -->
		<section class="c_section banner banner_culture">
			<div class="c_inner">
				<div class="img_wrapper culture">
					<div class="icon_wrapper">
						<!-- <img src="@/assets/images/banner/icon_culture.png" alt="아이콘" class="icon" /> -->
					</div>
				</div>
			</div>

			<!-- 페이지타이틀 START -->
			<div class="c_inner">
				<div class="page_text">
					<h2 v-textMotion>
						<span class="textMotion_target">좋은 기업 문화와 좋은 업무 환경에서</span>
						<br />
						<span class="textMotion_target">더 좋은 솔루션이 나온다는 믿음으로</span>
						<br />
						<span class="textMotion_target"><span class="text_deco"></span>위아오너는 더 나은 <span class="text_deco">창업문화</span>를 만들고 있습니다.</span>
					</h2>
				</div>
			</div>
			<!-- 페이지타이틀 END -->

		</section>
		<!-- 상단배너 END -->

		<section class="white">
			<h1>* 내용이 추가될 예정입니다 *</h1>
		</section>

		<!-- 컨텐츠 START -->
		<!-- <section class="c_section culture_content">
			<div class="c_inner">
				<ul>
					<li class="skewAnimation">
						<div v-imageMotion="{rootMargin:'0% 0px 0%'}" class="img_wrapper">
							<div class="img_holder"></div>
						</div>
						<div class="text_wrapper">
							<div class="icon_wrapper">
								<img src="@/assets/images/culture/icon_culture_01.png" alt="아이콘" />
							</div>
							<div v-fadein:reverse class="text">
								<h2 v-textMotion><span class="textMotion_target"><span class="text_deco">수평적 호칭</span></span></h2>
								<p>
									직급에 관계없이 이름으로만 호칭하고 <br />
									상하관계 없이 존칭으로 상대를 존중합니다.
								</p>
							</div>
						</div>
					</li>
					<li class="skewAnimation">
						<div v-imageMotion="{rootMargin:'0% 0px 0%'}" class="img_wrapper">
							<div class="img_holder"></div>
						</div>
						<div class="text_wrapper">
							<div class="icon_wrapper">
								<img src="@/assets/images/culture/icon_culture_02.png" alt="아이콘" />
							</div>
							<div v-fadein:reverse class="text">
								<h2 v-textMotion><span class="textMotion_target"><span class="text_deco">상호 간 존중</span></span></h2>
								<p>
									언제나 서로를 배려하고 <br />
									존중하는 자세로 대합니다.
								</p>
							</div>
						</div>
					</li>
					<li class="skewAnimation">
						<div v-imageMotion="{rootMargin:'0% 0px 0%'}" class="img_wrapper">
							<div class="img_holder"></div>
						</div>
						<div class="text_wrapper">
							<div class="icon_wrapper">
								<img src="@/assets/images/culture/icon_culture_03.png" alt="아이콘" />
							</div>
							<div v-fadein:reverse class="text">
								<h2 v-textMotion><span class="textMotion_target"><span class="text_deco">워라밸</span></span></h2>
								<p>
									불필요한 야근 없이 충분한 휴식으로 <br />
									일과 삶의 균형을 맞추고 있습니다.
								</p>
							</div>
						</div>
					</li>
					<li class="skewAnimation">
						<div v-imageMotion="{rootMargin:'0% 0px 0%'}" class="img_wrapper">
							<div class="img_holder"></div>
						</div>
						<div class="text_wrapper">
							<div class="icon_wrapper">
								<img src="@/assets/images/culture/icon_culture_04.png" alt="아이콘" />
							</div>
							<div v-fadein:reverse class="text">
								<h2 v-textMotion><span class="textMotion_target"><span class="text_deco">의사 표현</span></span></h2>
								<p>
									솔직한 의사 표현으로 <br />
									서로 명확한 인식 공유를 합니다.
								</p>
							</div>
						</div>
					</li>
					<li class="skewAnimation">
						<div v-imageMotion="{rootMargin:'0% 0px 0%'}" class="img_wrapper">
							<div class="img_holder"></div>
						</div>
						<div class="text_wrapper">
							<div class="icon_wrapper">
								<img src="@/assets/images/culture/icon_culture_05.png" alt="아이콘" />
							</div>
							<div v-fadein:reverse class="text">
								<h2 v-textMotion><span class="textMotion_target"><span class="text_deco">주도적 개선</span></span></h2>
								<p>
									사소한 부분도 지나치지 않고 <br />
									주도적으로 개선시킵니다.
								</p>
							</div>
						</div>
					</li>
					<li class="skewAnimation">
						<div v-imageMotion="{rootMargin:'0% 0px 0%'}" class="img_wrapper">
							<div class="img_holder"></div>
						</div>
						<div class="text_wrapper">
							<div class="icon_wrapper">
								<img src="@/assets/images/culture/icon_culture_06.png" alt="아이콘" />
							</div>
							<div v-fadein:reverse class="text">
								<h2 v-textMotion><span class="textMotion_target"><span class="text_deco">책임</span></span></h2>
								<p>
									주어진 업무와 일정을 준수하기 위해 <br />
									언제나 최선을 다합니다.
								</p>
							</div>
						</div>
					</li>
					<li class="skewAnimation">
						<div v-imageMotion="{rootMargin:'0% 0px 0%'}" class="img_wrapper">
							<div class="img_holder"></div>
						</div>
						<div class="text_wrapper">
							<div class="icon_wrapper">
								<img src="@/assets/images/culture/icon_culture_07.png" alt="아이콘" />
							</div>
							<div v-fadein:reverse class="text">
								<h2 v-textMotion><span class="textMotion_target"><span class="text_deco">휴식</span></span></h2>
								<p>
									사유없는 연월자 신청으로 <br />
									편안한 휴식을 보장합니다.
								</p>
							</div>
						</div>
					</li>
					<li class="skewAnimation">
						<div v-imageMotion="{rootMargin:'0% 0px 0%'}" class="img_wrapper">
							<div class="img_holder"></div>
						</div>
						<div class="text_wrapper">
							<div class="icon_wrapper">
								<img src="@/assets/images/culture/icon_culture_08.png" alt="아이콘" />
							</div>
							<div v-fadein:reverse class="text">
								<h2 v-textMotion><span class="textMotion_target"><span class="text_deco">탄력근무제</span></span></h2>
								<p>
									출퇴근 시간을 정해진 범위 내에서 <br />
									자율적으로 실행합니다.
								</p>
							</div>
						</div>
					</li>
				</ul>
			</div>
		</section> -->
		<!-- 컨텐츠 END -->
	</div>
</template>

<script>
export default {
	data(){
		return{
			windowSize : window.innerWidth,
		}
	},
	mounted(){
		window.addEventListener('resize', this.setWindowSize)
		this.$nextTick(() => {
			const skewAnimation = document.querySelectorAll('.skewAnimation');

			let currentPos = window.scrollY;

			const update = () => {
				const newPos = window.scrollY;
				const diff = newPos - currentPos;
				const speed = diff * 0.1;
				if(newPos != 0 && currentPos !=0 && this.windowSize>=768){
					skewAnimation.forEach(el => {
						el.style.transform = `skewY(${ speed }deg)`;
					});
				}
				currentPos = newPos;
				this.requestID = requestAnimationFrame(update);
			}
			update();
		})
	},
	unmounted(){
		cancelAnimationFrame(this.requestID);
		window.removeEventListener('resize', this.setWindowSize)
	},
	methods:{
		setWindowSize(){
			this.windowSize = window.innerWidth
		}
	}
}
</script>

<style scoped src="@/assets/css/layout.css">
</style>
<style scoped src="@/assets/css/culture.css">
</style>